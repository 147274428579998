export function catalogItemEditingAllowed(item) {
  return !isMandatoryCatalogItem(item) && !isRecommendedCatalogItem(item);
}

export function catalogItemDeletionAllowed(item) {
  return !isMandatoryCatalogItem(item);
}

export function getRestrictionInfoFor(item) {
  var info = {
    Color: "grey",
    Text: "",
  };

  if (isMandatoryCatalogItem(item)) {
    info.Text = "Pakollinen";
    info.Color = "red";
  } else if (isRecommendedCatalogItem(item)) {
    info.Text = "Suositeltava";
    info.Color = "orange";
  } else if (isUncontrolledCatalogItem(item)) {
    info.Text = "Ei rajoitusta";
    info.Color = "green";
  }
  return info;
}

export function isMandatoryCatalogItem(catalogItem) {
  return catalogItem?.restrictionMode?.toUpperCase() === "MANDATORY";
}

export function isRecommendedCatalogItem(catalogItem) {
  return catalogItem?.restrictionMode?.toUpperCase() === "RECOMMENDED";
}

export function isUncontrolledCatalogItem(catalogItem) {
  return catalogItem?.restrictionMode?.toUpperCase() === "UNCONTROLLED";
}

export function getItemTypeTextsFor(item) {
  var dict = {};
  /* eslint-disable */
  dict["TEMPLATECOLLECTION"]  = ["tarkistuslistapohja", "tarkistuslistapohjan", "tarkistuslistapohjaa", "tarkistuslistapohjaan"];
  dict["COLLECTION"]          = ["yksikkö",             "yksikön",              "yksikköä",             "yksikköön"            ];
  dict["CATEGORY"]            = ["kansio",              "kansion",              "kansiota",             "kansioon"             ];
  dict["CHECKLIST"]           = ["tarkistuslista",      "tarkistuslistan",      "tarkistuslistaa",      "tarkistuslistaan"     ];
  dict["INFOLIST"]            = ["tarkistuslista",      "tarkistuslistan",      "tarkistuslistaa",      "tarkistuslistaan"     ];
  dict["CHECKLISTROW"]        = ["tarkistuslistarivi",  "tarkistuslistarivin",  "tarkistuslistariviä",  "tarkistuslistariviin" ];
  /* eslint-enable */

  var texts = {
    Basic: "kohde",
    Genetive: "kohteen",
    Partitive: "kohdetta",
    Objective: "kohteeseen",
  };

  if (dict[item.type?.toUpperCase()] != null) {
    texts.Basic = dict[item.type.toUpperCase()][0];
    texts.Genetive = dict[item.type.toUpperCase()][1];
    texts.Partitive = dict[item.type.toUpperCase()][2];
    texts.Objective = dict[item.type.toUpperCase()][3];
  }

  return texts;
}

export function getItemTypeIconFor(item) {
  var dict = {};
  /* eslint-disable */
  dict["TEMPLATECOLLECTION"]  = ["mdi-view-list-outline",     "primary"];
  dict["COLLECTION"]          = ["mdi-ambulance",             "primary"];
  dict["CATEGORY"]            = ["mdi-folder",                "amber"  ];
  dict["CHECKLIST"]           = ["mdi-format-list-checkbox",  "primary"];
  dict["INFOLIST"]            = ["mdi-text-box-outline",      "primary"];
  dict["CHECKLISTROW"]        = ["mdi-checkbox-outline",      "primary"];
  /* eslint-enable */

  var icon = {
    name: "mdi-exclamation",
    color: "primary",
  };

  if (item) {
    if (dict[item.type?.toUpperCase()] != null) {
      icon.name = dict[item.type.toUpperCase()][0];
      icon.color = dict[item.type.toUpperCase()][1];
    }
  }

  return icon;
}

export function getRestrictionModeByNumber(restrictionValue) {
  if (restrictionValue === 0) {
    return "Mandatory";
  } else if (restrictionValue === 1) {
    return "Recommended";
  } else if (restrictionValue === 2) {
    return "Uncontrolled";
  }
  return "Unknown";
}
